<template>
  <ion-grid>
    <ion-row class="ion-text-center ion-align-items-center min_full_height">
      <ion-col>
        <ion-row>
          <ion-col size-md="10" offset-md="1">
            <img
              :onload="imgOnLoad"
              class="image"
              :src="'/assets/img/faces/' + data.person.getReal().img + '.jpg'"
            />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-md="10" offset-md="1" style="font-size: 1.5rem;">
            {{ data.person.getReal().name }}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-md="10" offset-md="1" style="font-size: 1.5rem;">
            {{ data.person.getReal().job }}
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>

  <back-logos></back-logos>
</template>

<script>
import { defineComponent } from "vue";
import BackLogos from "@/components/BackLogos";
import { later } from "@/services/Helper";
import { QuestionMixin } from "@/mixins/QuestionMixin";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import { storage } from "@/services/Storage";

export default defineComponent({
  name: "RememberFace",
  components: {
    BackLogos,
  },
  mixins: [QuestionMixin, IonComponentsMixin],
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  methods: {
    imgOnLoad: async function() {
      await later(8000);
      await storage.set('time', Date.now());
      await this.next();
    }
  },
});
</script>

<style scoped>
.image {
    height: 50vh;
}

@media (min-height: 500px) {
    ion-row {
        padding-bottom: 2vh;
    }
}
</style>
