<template>
  <ion-grid id="exam-grid" style="height: 100%; display: flex; flex-flow: column;">
    <ion-row>
      <ion-col class="ion-text-center">
        <wait-time
          ref="questionWait"
          :data="data.questionWait"
          @answered="timeFinished"
        ></wait-time>
      </ion-col>
    </ion-row>

    <template v-if="order !== data.questions.length">
      <ion-row style="flex-grow: 1; align-items: center; justify-content: space-around;">
        <ion-button :disabled="order === 0" @click="prev">
          <ion-icon :icon="arrowBackCircleOutline"></ion-icon>
        </ion-button>

        <ion-col
          size-xs="10"
          size-sm="6"
          size-md="6"
        >
          <form @submit="submitForm">
            <template v-for="(pages, i) in data.questions" :key="pages">
              <template v-if="order === i">
                <template v-for="(question, k) in pages" :key="question">
                  <ion-row class="max-width" style="padding-bottom: 10px">
                    <ion-label class="max-width" style="font-size: 1.2rem">{{
                      $t(question.text)
                    }}</ion-label>

                    <ion-radio-group
                      v-if="question.options.length != 0"
                      class="max-width"
                      @ionChange="set(i + '.' + k + '.radio', $event)"
                    >
                      <ion-item
                        v-for="option in question.options"
                        :key="option"
                      >
                        <ion-label>{{ $t(option) }}</ion-label>
                        <ion-radio slot="start" :value="option"></ion-radio>
                      </ion-item>
                    </ion-radio-group>

                    <ion-item class="max-width" v-if="question.inputText">
                      <ion-input
                        @ionChange="set(i + '.' + k + '.text', $event)"
                        :placeholder="$t(question.placeholder)"
                      ></ion-input>
                    </ion-item>
                  </ion-row>
                </template>
              </template>
            </template>
          </form>
        </ion-col>
        <ion-button
              :disabled="order === data.questions.length - 1"
              class="rightButton"
              @click="follow"
            >
             <ion-icon :icon="arrowForwardCircleOutline"></ion-icon>
              
            </ion-button>
      </ion-row>

      <ion-row>
        <ion-col
          offset-xs="1"
          size-xs="10"
          offset-sm="2"
          size-sm="8"
          offset-md="4"
          size-md="4"
        >
          <ion-row style="justify-content: center">
            <span
              class="dot"
              v-for="(numPage, ind) in data.questions"
              :key="numPage"
              :class="{ marked: order === ind }"
            ></span>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col
          offset-xs="1"
          size-xs="10"
          offset-sm="2"
          size-sm="8"
          offset-md="2"
          size-md="8"
        >
          <ion-row style="padding-top: 10px; justify-content: center;">            
            <ion-button
              v-if="order === data.questions.length - 1"
              class="rightButton"
              @click="finish"
            >
              {{ $t("form.buttons.continue") }}
            </ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
    </template>

    <template v-if="showWait">
      <ion-row
        class="ion-text-center ion-align-items-center"
        style="flex-grow: 0.85"
      >
        <ion-col size-md="10" offset-md="1">
          <span style="white-space: pre-line">{{ $t("form.text.wait") }}</span>
        </ion-col>
      </ion-row>
    </template>
  </ion-grid>
</template>

<script>
import { defineComponent } from "vue";
import { QuestionMixin } from "@/mixins/QuestionMixin";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import { arrowBackCircleOutline, arrowForwardCircleOutline } from "ionicons/icons";
import WaitTime from "@/components/questions/WaitTime";
import { t } from "@/i18n";

export default defineComponent({
  name: "BreakForm",
  components: {
    WaitTime
  },
  mixins: [QuestionMixin, IonComponentsMixin],
  props: {
    data: Object,
  },
  data() {
    return {
      order: 0,
      isTimeOver: false,
      builder: {},
      showWait: false,
      answers: {},
      questionIndex: null,
      arrowBackCircleOutline,
      arrowForwardCircleOutline
    };
  },
  methods: {
    init: async function (current_question_index) {
      this.questionIndex = current_question_index;
      await this.$refs.questionWait.init();
    },
    next: async function () {
      let answer = await this.getForm();
      this.$emit("answered", {questionIndex: this.questionIndex, answer: answer});
    },
    set(value, $event) {
        this.answers[value] = $event.detail.value;
    },
    follow() {
      this.order++;
    },
    prev() {
      this.order--;
    },
    timeFinished() {
      this.isTimeOver = true;
      this.next();
    },
    finish() {
      this.order++;
      this.showWait = true;
    },
    async getForm() {
      let formatedForm = {
          type: 'BreakForm'
      };

      for (let p = 0; p < this.data.questions.length; p++) {
        for (let q = 0; q < this.data.questions[p].length; q++) {
          let idtf = p + "." + q;
          let radio = this.answers[idtf + ".radio"]
            ? this.answers[idtf + ".radio"]
            : "";
          let text = this.answers[idtf + ".text"]
            ? this.answers[idtf + ".text"]
            : "";

          if (radio !== "") {
            radio = t(radio);
          }

          if (radio != "" || text != "") {
            formatedForm[t(this.data.questions[p][q].name)] =
              radio + ". " + text;
          }
        }
      }

      return formatedForm;
    },
  },
});
</script>

<style scoped>
.image {
  height: 50vh;
}

.rightButton {

}

.dot {
  margin: 10px;
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.marked {
  background-color: #000;
}

@media (min-height: 500px) {
  ion-row {
    padding-bottom: 5vh;
  }
}
</style>