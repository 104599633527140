<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row class="min_full_height">
          <ion-col>
            <ion-row class="ion-text-center ion-align-items-center fill_height">
              <ion-col>
                <ion-row>
                  <ion-col>{{ $t("home.title") }} </ion-col>
                </ion-row>
                <template v-if="isMobile()">
                  <ion-row>
                    <ion-col style="white-space: pre-line">
                      El test no es pot fer des de dispositius mòbils. Si us
                      plau, accediu des d'un ordinador o una tauleta per poder
                      dur a terme el test satisfactòriament.
                    </ion-col>
                  </ion-row>
                  <hr style="background-color: black; width: 80%" />
                  <ion-row>
                    <ion-col>
                      The test cannot be performed from mobile devices. Please,
                      access from a computer or a tablet to be able to carry out
                      the test successfully.
                    </ion-col>
                  </ion-row>
                </template>
                <template v-else>
                  <ion-row>
                    <ion-col style="white-space: pre-line">
                      {{ $t("home.subtitle") }}
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-text-center">
                    <ion-col>
                      <img
                        class="logo"
                        src="@/assets/img/logos/logo_face_sol.png"
                      />
                    </ion-col>
                  </ion-row>
                  <ion-row class="video_wrap" v-if="firstVideo != null">
                    <iframe
                      width="560"
                      height="315"
                      :src="firstVideo"
                      allow="autoplay"
                    ></iframe>
                  </ion-row>
                  <ion-row>
                    <ion-col
                      offset-xs="0"
                      size-xs="12"
                      offset-sm="1"
                      size-sm="10"
                      offset-md="1"
                      size-md="10"
                      offset-lg="3"
                      size-lg="6"
                    >
                      <ion-item>
                        <ion-label>{{ $t("home.select_language") }}</ion-label>
                        <ion-select
                          interface="action-sheet"
                          :v-model="selectedLanguage"
                          :value="selectedLanguage"
                          :cancelText="$t('shared.cancel')"
                          @ionChange="langChanged($event)"
                        >
                          <ion-select-option value="ca"
                            >Català</ion-select-option
                          >
                          <ion-select-option value="es"
                            >Español</ion-select-option
                          >
                          <ion-select-option value="en"
                            >English</ion-select-option
                          >
                        </ion-select>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-button @click="startTest()">{{
                        $t("home.enter")
                      }}</ion-button>
                    </ion-col>
                  </ion-row>
                </template>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";

import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";


import { controller } from "@/services/Controller";
import { setI18nLanguage } from "@/i18n";
import { alertController } from '@ionic/vue';
import { getCurrentLocale } from '@/i18n';


export default defineComponent({
  name: "HomeView",
  components: {},
  mixins: [IonComponentsMixin],
  data() {
    return {
      firstVideo: null,
      selectedLanguage: getCurrentLocale()
    };
  },
  ionViewWillEnter() {
    this.firstVideo =
      "https://www.youtube.com/embed/IlKfSqfMevM?autoplay=1&controls=0";
  },
  ionViewWillLeave() {
    this.firstVideo = null;
  },
  methods: {
    isSmallScreen() {
      const clientjs = this.clientjs;

      const dpi = window.devicePixelRatio;
      const resolution = clientjs.getCurrentResolution().split("x");

      if (dpi && resolution.length > 1) {
        const y = Number(resolution[1]) / dpi;
        const x = Number(resolution[0]) / dpi;
        const diagonal = Math.sqrt(y * y + x * x);

        return diagonal < 500;
      }

      return false;
    },
    isMobile() {
      const clientjs = this.clientjs;

      if (!clientjs.isMobile()) return false;
      if (clientjs.isIpad()) return false;
      return this.isSmallScreen();
    },
    startTest() {
      if (controller.isTestStarted()) {
        this.showActionSheet();
      } else {
        this.startNewTest();
      }
    },
    async startNewTest() {
      await controller.clearPreviousTest();
      this.$router.push("/legal-text");
    },
    continueTest() {
      this.$router.push("/exam");
    },
    showActionSheet: async function () {
      const actionSheet = await alertController.create({
        header: this.$t("home.action.ask_continue_test"),
        cssClass: "action_sheet",
        buttons: [
          {
            text: this.$t("home.action.reset_test"),
            role: "destructive",
            icon: "trash",
            handler: () => this.showActionSheetConfirmation(),
          },
          {
            text: this.$t(
              "home.action.continue_previous_test"
            ),
            icon: "caret-forward-circle",
            handler: () => this.continueTest(),
          },
        ],
      });

      await actionSheet.present();
    },
    showActionSheetConfirmation: async function () {
      const actionSheet = await alertController.create({
        header: this.$t(
          "home.action.ask_continue_test_confirmation"
        ),
        cssClass: "action_sheet",
        buttons: [
          {
            text: this.$t(
              "home.action.reset_test_accept"
            ),
            role: "destructive",
            icon: "trash",
            handler: () => this.startNewTest(),
          },
          {
            text: this.$t(
              "home.action.reset_test_decline"
            ),
            icon: "caret-forward-circle",
            handler: () => this.continueTest(),
          },
        ],
      });

      await actionSheet.present();
    },
    langChanged: async function(event) {
      this.selectedLanguage = event.detail.value;
      await setI18nLanguage(event.detail.value);
      
    }
  },
});
</script>

<style scoped>
.logo {
  height: 90px;
  margin: 10px;
}
.fill_height {
  min-height: calc(100% - 54px);
}
.video_wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
</style>