<template>
    <ion-grid>
    <ion-row class="ion-text-center ion-align-items-center">
      <ion-col>
        <ion-row>
          <ion-col size-md="10" offset-md="1">
            <img v-if="data" class="image" :src="'/assets/img/faces/' + data.person.getReal().img + '.jpg'" />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-md="10" offset-md="1">
            {{ $t("img_to_name_and_profession.question") }}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-md="10" offset-md="1">
            <record-audio @audioRecorded="next($event)"></record-audio>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
  <back-logos></back-logos>
</template>

<script>
import { defineComponent } from "vue";
import BackLogos from "@/components/BackLogos";
import RecordAudio from "@/components/RecordAudio";
import { QuestionMixin } from "@/mixins/QuestionMixin";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import { AnswerImgToNameAndProfession } from "@/models/AnswerImgToNameAndProfession";

export default defineComponent({
  name: "RememberFace",
  components: {
    BackLogos,
    RecordAudio
  },
  mixins: [QuestionMixin, IonComponentsMixin],
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  methods: {
    next: async function($event) {
        const answer = new AnswerImgToNameAndProfession(this.data, $event);
        this.$emit('answered', {questionIndex: this.questionIndex, answer: answer});
    }
  },
});
</script>

<style scoped>
.image {
    height: 50vh;
}

@media (min-height: 500px) {
    ion-row {
        padding-bottom: 1vh;
    }
}
</style>
